import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import BenifitDetails from '~components/benifit-details'
import ComparisonChart from '~components/comparison-chart'
import Layout from '~components/layout'

// Images
import curved from '~images/curved-1-lg-5.svg'
import knowledgebaseCommunity from '~images/knowledgebase-community.svg'

const SoftwareBenefits = () => {
  const img = useStaticQuery(graphql`
    query {
      yellowpencils: file(relativePath: { eq: "stil.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout>
        <main id="content" role="main">

          {/* Hero Section */}
          <div id="SVGpricingHero" className="position-relative gradient-half-primary-v1">
            <div className="container space-3 space-top-md-4 space-top-lg-3 space-bottom-lg-0">
              <div className="row justify-content-lg-between align-items-lg-center">
                <div className="col-lg-5 mb-7 mb-lg-0">
                  {/* Info */}
                  <h1 className="display-4 font-size-md-down-5 text-white font-weight-semi-bold">We can help you <span className="text-warning">boost</span> your services</h1>
                  <p className="text-white-70">With software that does what you need it to, so you can focus on what really matters, your clients</p>

                  {/* End Info */}
                </div>

                <div className="col-lg-6 d-none d-lg-inline-block">
                  {/* SVG Icon */}
                  <figure className="ie-knowledgebase-community-2">
                    <img className="img-fluid" src={knowledgebaseCommunity} alt="Woman using software" />
                  </figure>
                  {/* End SVG Icon */}
                </div>
              </div>
            </div>

            {/* SVG Bottom Shape */}
            <figure className="position-absolute right-0 bottom-0 left-0">
              <img className="img-fluid" src={curved} aria-hidden="true" alt="" />
            </figure>
            {/* End SVG Bottom Shape */}
          </div>
          {/* End Hero Section */}

          {/* Comparison Chart */}
          <div className="container">
            <div className="row align-items-lg-center">
              <ComparisonChart />
            </div>
          </div>
          {/* End Comparison Chart */}

          {/* Benifit Details */}
          {/* <div className="container space-2 space-md-3">
            <div className="row align-items-lg-center">
              <BenifitDetails />
            </div>
          </div> */}
          {/* End Benifit Details */}

        </main>
      </Layout>
    </>
  )
}

export default SoftwareBenefits
