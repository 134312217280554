import React from 'react'
import { Link } from 'gatsby'

const ComparisonChart = () => {
  const featuresGold = [
    "Client Goals and Supports Charting",
    "Person Centered Supports",
    "Daily Notes",
    "Electronic Visit Verification (EVV)",
    "Medication Administration Tracking (eMAR)",
    "Client Billing Reports (State Specific)",
    "Incident Reporting",
    "Attendance Tracking",
    "ADL Charting",
    "Client Inventory Tracking",
    "Client Income/Expense Tracking",
    "Client Document Upload",
    "Shift Change Notes",
    "Vital Signs Tracking",
    "Staff Document Upload",
    "Employee Timecard and Reporting",
    "Employee Onboarding and E-Sign (coming soon)",
    "Nursing Notes Tracking",
    "Client Behavior Tracking"
  ];

  const featuresAddOns = [
    "Pharmacy eMAR Integration",
    "State Billing Integration",
    "State Incident Reporting Integration",
    "State E-Verify Integration"
  ];


  return (
    <>
      {/* Pricing Section */}
      <div className="container space-2 space-lg-3">
        {/* Table */}
        <div className="table-responsive-lg mx-lg-auto">
          <table className="table table-striped table-borderless">
            <thead className="text-center">
              <tr>
                <th scope="col" className="w-50"></th>
                <th scope="col" className="w-25">
                  Gold
                  <span className="badge badge-warning font-weight-medium badge-pill ml-1">Most Popular</span>
                  <Link to="/demo"><small className="d-block text-secondary">$10/client</small></Link>
                </th>
                <th scope="col" className="border-left w-25">
                  Add-On's
                  <Link to="/demo"><small className="d-block text-secondary">Request Pricing</small></Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {featuresGold.concat( featuresAddOns).map(feature => (
                <tr key={feature}>
                  <th scope="row" className="font-size-1 font-weight-normal py-3 px-4">{feature}</th>
                  <td className="text-center p-3">
                    {featuresGold.includes(feature)  && <small className="fas fa-check text-success"></small>}
                  </td>
                  <td className="text-center text-secondary border-left p-3">
                    {featuresAddOns.includes(feature) && <small className="fas fa-check text-success"></small>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* End Table */}
      </div>
      {/* End Pricing Section */}
    </>
  )
}

export default ComparisonChart
